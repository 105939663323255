<template>
  <div
    class="co-flex-col pa-4"
  >
    <v-row>
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`wk_${i}`"
          cols="12"
          md="4"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              :color="backColor"
              flat
              tile
            >
              <v-img
                :aspect-ratio="ratio"
                :src="item.cover"
                height="inherit"
                @click="toContent(i, item)"
              >
                <!-- <div class="co-flex-col co-justify-between co-h-full">
                  <div
                    class="co-flex-col co-w-full co-flex-grow "
                    @click="toContent(i, item)"
                  />
                  <div
                    class="co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-4"
                    style="opacity: 0.70;"
                  >
                    <span
                      class="text-body-1 white--text text-truncate"
                      @click="toContent(i, item)"
                    >
                      {{ item.title }}
                    </span>
                  </div>
                </div> -->
              </v-img>
              <div
                v-if="item.title"
                class="text-uppercase co-text-md px-4 py-2 co-text-line1"
                style="line-height: 32px; cursor: pointer;"
                @click="toContent(i, item)"
              >
                {{ item.title }}
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              感谢浏览我遇见最美的光，我们正在完善内容
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/mix.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.ACTIVITY]

  export default {
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        type: part.type,
        ratio: part.ratio,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnDel: {
          icon: 'mdi-close'
        },
      }
    },
    created () {
      this.configParams()
      this.loadContents()
    },
    methods: {
      toContent (index, item) {
        const params = {
          activityId: item.activityId,
          type: item.type,
          title: item.title
        }
        api.page.navigateBy(this, 'activityDetail', params)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configParams () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.total = res.total
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 9
            pagination.length = Math.ceil(res.total / pageSize)
          }
        }

        me.params = web.activity.getParams({
          type: me.type,
          state: web.comm.States.PUBLISH,
          caches: this.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
